<template>
  <div class="ticket" v-if="!isProcessing">
    <p class="ticket__more">
      <router-link class="ticket__more__link" :to="{ name: 'ticket_available' }">利用可能な回数券を見る</router-link>
      / <router-link class="ticket__more__link" :to="{ name: 'ticket_histories' }">ご利用履歴を見る</router-link>
    </p>
    <ticket-card class="ticket__card" :ticket="displayedTicket" :leftNumber="availableTickets.length" />
    <ticket-detail class="ticket__detail" />
  </div>
</template>

<script>
import TicketCard from '@/components/ticket/card'
import TicketDetail from '@/components/ticket/detail'

export default {
  components: { TicketCard, TicketDetail },
  name: 'ticket',
  async mounted () {
    // 回数券がなければ404へ
    if (!this.tickets) {
      this.$router.push({ name: 'notfound' })
      return
    }

    const existAvailableTickets = this.availableTickets.length > 0

    if (existAvailableTickets) {
      const stid = this.displayedTicket.stid
      await this.$store.dispatch('settingTickets/getSettingTicket', stid)
    } else {
      await this.$store.dispatch('settingTickets/getActivateSettingTicket')
    }

    if (!this.settingTicket) {
      this.$router.push({ name: 'notfound' })
      return
    }

    // 利用可能チケットが存在しまだ履歴を取得していない場合は履歴が更新される可能性があるのでこのタイミングで取得する
    if (existAvailableTickets && !this.usedTicketHistories.length) await this.$store.dispatch('tickets/getUsedTicketHistories', this.uid)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} 発行済み回数券情報
     */
    tickets () {
      return this.$store.getters['tickets/tickets']
    },
    /**
     * @return {Object[]} 利用可能な回数券情報
     */
    availableTickets () {
      return this.$store.getters['tickets/availableTickets']
    },
    /**
     * @return {Object} 表示する回数券情報（利用可能な回数券がない場合は空オブジェクト）
     */
    displayedTicket () {
      return this.availableTickets.length > 0 ? this.availableTickets[0] : {}
    },
    /**
     * @return {Object} 現在の発行中の回数券情報
     */
    settingTicket () {
      return this.$store.getters['settingTickets/settingTicket']
    },
    /**
     * @return {Object[]} 利用済みの回数券の履歴情報
     */
    usedTicketHistories () {
      return this.$store.getters['tickets/usedHistories']
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__more {
    margin: 0;
    font-size: 1.2rem;
    text-align: right;
    &__link {
      color: $concept_color !important;
    }
  }
  &__card {
    margin-top: 10px;
  }
  &__detail {
    margin-top: 30px;
  }
}
</style>
