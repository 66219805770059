<template>
  <div class="ticket-card">
    <h3 class="ticket-card__title">
      <v-icon class="ticket-card__title__icon">credit_card</v-icon>回数券
      <span class="ticket-card__title__left">（残り枚数<strong>{{ leftNumber }}枚</strong>）</span>
    </h3>
    <h2 class="ticket-card__name">{{ settingTicket.name }}</h2>
    <div class="ticket-card__line" />
    <p class="ticket-card__expired">{{ isAvailable ? '有効期限：' + formatted(ticket.endAt.toDate(), 'YYYY/MM/DD') : ''  }}</p>
    <v-btn class="ticket-card__btn" :disabled="!isAvailable" @click="$refs.confirm.open()">{{ isAvailable ? '利用する' : '利用できません'}}</v-btn>
    <p class="ticket-card__number">{{ isAvailable ? '回数券番号：' + ticketNumber : '' }}</p>
    <confirm-dialog ref="confirm" title="回数券の利用" msg="ご利用方法に従い回数券をご利用ください。誤って操作すると取り消しができませんのでご了承ください。"
                    @do-action="usedTicket()" />
  </div>
</template>

<script>
import moment from '@/assets/lib/moment'

import ConfirmDialog from '@/components/common/confirm'

export default {
  mixins: [moment],
  components: { ConfirmDialog },
  props: {
    // 表示する回数券情報
    ticket: {
      type: Object,
      required: true
    },
    // 残り枚数
    leftNumber: {
      type: Number,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} 回数券番号
     */
    ticketNumber () {
      return this.isAvailable ? this.ticket.tiid : null
    },
    /**
     * @return {Boolean} 利用可能かどうか
     */
    isAvailable () {
      return this.leftNumber > 0
    },
    /**
     * @return {Object} 現在の発行中の回数券情報
     */
    settingTicket () {
      return this.$store.getters['settingTickets/settingTicket']
    }
  },
  methods: {
    /**
     * 回数券を利用する
     */
    async usedTicket () {
      this.$store.commit('setSubmitting', true)

      // 回数券の利用
      const tiid = this.ticket.tiid
      await this.$store.dispatch('tickets/usedTicket', tiid)

      // 回数券利用完了画面へ遷移
      this.$router.push({ name: 'ticket_done' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket-card {
  width: 100vw;
  max-width: $max_width;
  padding: 30px $padding_width;
  margin-left: -$padding_width;
  background-color: $gray_lighten_color;
  &__title {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 3rem;
    text-align: center;
    &__icon {
      margin-right: 10px;
      font-size: 3rem;
      color: $black_lighten_color;
    }
    &__left {
      font-size: 1rem;
    }
  }
  &__name {
    margin: 30px 0 0;
    font-size: 1.8rem;
    text-align: center;
  }
  &__line {
    width: 80px;
    height: 2px;
    margin: 30px auto 0;
    background-color: $gray_color;
  }
  &__expired {
    height: 14px;
    margin: 10px 0 0;
    font-size: 1.2rem;
    color: $gray_color;
    text-align: center;
  }
  &__btn {
    display: block;
    margin: 20px auto 0;
    font-size: 1.8rem;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: auto;
      padding: 20px 50px;
    }
    &.theme--light.v-btn.v-btn--has-bg,
    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: $white_color !important;
    }
    &.theme--light.v-btn.v-btn--disabled {
      color: $gray_color !important;
      box-shadow: none;
    }
  }
  &__number {
    height: 14px;
    margin: 10px 0 0;
    font-size: 1.2rem;
    color: $gray_color;
    text-align: right;
  }
}
</style>
