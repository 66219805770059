<template>
  <div class="ticket-detail">
    <p class="ticket-detail__description" v-html="createAutolink(description)" />
    <h2 class="ticket-detail__title">ご利用方法</h2>
    <p class="ticket-detail__description" v-html="createAutolink(usage)" />
    <h2 class="ticket-detail__title">ご利用時の注意事項</h2>
    <p class="ticket-detail__description" v-html="createAutolink(warning)" />
  </div>
</template>

<script>
import url from '@/assets/lib/url'

export default {
  mixins: [url],
  props: {
  },
  computed: {
    /**
     * @return {Object} 現在の発行中の回数券情報
     */
    settingTicket () {
      return this.$store.getters['settingTickets/settingTicket']
    },
    /**
     * @return {String} 回数券の説明文
     */
    description () {
      return this.settingTicket.description
    },
    /**
     * @return {String} 回数券の利用方法
     */
    usage () {
      return this.settingTicket.usage
    },
    /**
     * @return {String} 回数券の注意事項
     */
    warning () {
      return this.settingTicket.warning
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket-detail {
  &__title {
    width: 100vw;
    max-width: $max_width;
    padding: 10px $padding_width;
    margin: 20px 0 0 -#{$padding_width};
    font-size: 1.2rem;
    background-color: $gray_lighten_color;
  }
  &__description {
    margin: 10px 0 0;
    font-size: 1.2rem;
    word-wrap: break-word;
    white-space: pre-wrap;
    &:first-child {
      margin: 0;
    }
  }
}
</style>
